import log from "loglevel";
import { useEffect, useState } from "react";

type WindowSize = {
  width?: number;
  height?: number;
};

const useWindowSizeObserver = () => {
  const [size, setSize] = useState<WindowSize>(() => ({
    width: 0,
    height: 0,
  }));

  const updateSize = () => {
    log.debug(
      `Updating window size to ${window.innerWidth}x${window.innerHeight}`
    );

    setSize({ width: window.innerWidth, height: window.innerHeight });
  };

  /**
   * @effect Set size to the current window height & width on mount, and
   * add an event listener to update this value on resize
   * @deps []
   */
  useEffect(() => {
    updateSize();
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return size;
};

export default useWindowSizeObserver;
