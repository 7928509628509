import React, { useEffect, useState } from "react";
import HomePage from "../pages/HomePage";
import ContactPage from "../pages/ContactPage";
import AboutPage from "../pages/AboutPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Navbar";
import ReactGA from "react-ga4";
import FAQPage from "../pages/FAQPage";
import NotSupported from "./NotSupported";

const GOOGLE_ANALYTICS_MEASUREMENT_ID = "G-ZKWMQ6Y9GD";

const NOT_SUPPORTED_TITLE =
  "CompoNet doesn't support mobile or tablet devices yet.";
const NOT_SUPPORTED_SUBTITLE = "Try visiting CompoNet from your computer!";

/**
 * 1. Note that the macintosh user agent is to detect newer iPads that use the Macintosh UA.
 * This regex should be paired with logic to check for a touchscreen to avoid matching on desktops
 * 2. Known tablets that will not be registered from this regex: Surface & Asus Zenbook
 *
 * Adapted from: https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
 */
const TABLET_USER_AGENT_REGEX = /android|ipad|macintosh|playbook|silk/i;
const MOBILE_USER_AGENT_REGEX =
  /android|webos|iphone|ipod|blackberry|windows phone/i;

function App() {
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

  useEffect(() => {
    ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID);
  }, []);

  /**
   * @effect Identify device support
   **/
  useEffect(() => {
    // ! Inspecting the user agent cannot guarantee that all mobile or tablet devices
    // will be matched on from the regexes of common devices that we are using
    const userAgent = window.navigator.userAgent;
    const isUAMatch = !!(
      userAgent.match(TABLET_USER_AGENT_REGEX) ||
      userAgent.match(MOBILE_USER_AGENT_REGEX)
    );

    setIsMobileOrTablet(isUAMatch && window?.navigator?.maxTouchPoints > 0);
  }, []);

  return isMobileOrTablet ? (
    <NotSupported
      title={NOT_SUPPORTED_TITLE}
      subtitle={NOT_SUPPORTED_SUBTITLE}
    />
  ) : (
    <div className="pt-24 min-h-screen">
      <BrowserRouter>
        <Navbar />
        <div className="h-full">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
