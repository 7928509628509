import React, { useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";
import emailjs, { EmailJSResponseStatus } from "emailjs-com";

export default function ContactPage() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/contact",
      title: "Contact Page",
    });
  }, []);

  const form = useRef<HTMLFormElement | null>(null);
  const [sent, setSent] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  // Handle input changes
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission using emailjs
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const serviceID = "service_yq6z00v";
    const templateID = "template_ca7lhhn";
    const publicKey = "c6sb8aMXnGDaehDMD";

    if (form.current) {
      emailjs.sendForm(serviceID, templateID, form.current, publicKey).then(
        () => {
          setSent(true);
        },
        (err: EmailJSResponseStatus) => {
          console.log("FAILED...", err);
        }
      );
    }
  };

  return (
    <div className="flex w-full h-screen">
      <div className="flex-grow ml-64 items-center justify-center w-full p-8 pt-12 overflow-y-auto">
        <h1 className="text-3xl font-bold mb-8">Send Feedback</h1>
        <div className="max-w-3xl space-y-6 text-left">
          <p className="text-lg ">
            Feel free to send us any feedback on the website, or contact us
            directly by reaching out to Shalin at{" "}
            <a
              href="mailto:shalinvs@gmail.com"
              className="text-founders-rock hover:underline"
            >
              shalinvs@gmail.com
            </a>
            , or Nathan at{" "}
            <a
              href="mailto:brooksnc@rose-hulman.edu"
              className="text-founders-rock hover:underline"
            >
              brooksnc@rose-hulman.edu
            </a>
            .
          </p>
          {sent ? (
            <p className="text-green-500 text-lg">
              Thank you for your feedback!
            </p>
          ) : (
            <form ref={form} onSubmit={handleSubmit} className="space-y-4">
              {/* Name Field */}
              <div>
                <label className="block mb-1 font-semibold" htmlFor="name">
                  Name (Optional)
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2"
                />
              </div>

              {/* Email Field */}
              <div>
                <label className="block mb-1 font-semibold" htmlFor="email">
                  Email (Optional)
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2"
                />
              </div>

              {/* Message Field */}
              <div>
                <label className="block mb-1 font-semibold" htmlFor="message">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  value={formData.message}
                  onChange={handleChange}
                  required
                  className="w-full border border-gray-300 p-2"
                />
              </div>
              <button
                type="submit"
                className="w-full h-12 border-2 border-black px-4 flex items-center justify-center shadow-lg bg-founders-rock hover:bg-founders-rock-600 text-white font-bold"
                onClick={() => {
                  ReactGA.event("submit_feedback");
                }}
              >
                Send Feedback
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
