import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";

export default function FAQPage() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/faq",
      title: "FAQ Page",
    });
  }, []);

  const faqs = [
    {
      question: "What is CompoNet?",
      answer: (
        <span>
          CompoNet is a powerful data visualization and analysis tool designed
          specifically for comparing and understanding the landscape of passive
          component technologies. It was developed by Dr. Nathan Brooks and
          Shalin Shah to facilitate a deeper understanding of component metrics
          such as cost, energy density, volume, and other electrical properties
          across a comprehensive dataset of over 1.2 million components. This
          project originally stemmed from research conducted by Dr. Brooks,
          which was published in this IEEE journal article:{" "}
          <a
            href="/Brooks2024_TPEL_Passive.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="text-founders-rock underline"
          >
            On the Size and Weight of Passive Components: Scaling Trends for
            High-Density Power Converter Designs
          </a>
          . The research explored methodologies for accurately quantifying
          passive component performance, focusing on important metrics like
          energy density, volumetric energy density, and overall component
          efficiency.
        </span>
      ),
    },
    {
      question: "Where does the component data come from?",
      answer: (
        <span>
          Our dataset has been compiled over three years of meticulous work. We
          sourced components primarily from DigiKey and Octopart, two of the
          largest electronic component distributors. By aggregating data from
          these sources, we were able to build a robust and up-to-date library
          of passive components, which forms the backbone of our analysis
          platform. The data includes over 1.2 million commercial capacitors and
          inductors, making it one of the largest collections of passive
          component data available for public use.
        </span>
      ),
    },
    {
      question: "How are the metrics derived?",
      answer: (
        <span>
          Most metrics displayed on CompoNet are derived directly from
          manufacturer-provided specifications and datasheets. However, for many
          passive components, datasheet specifications alone do not provide a
          complete picture. To address this, we use empirical expressions
          validated by Dr. Brooks’s research to estimate key performance
          characteristics such as mass, volume, and energy density. For example,
          we estimate volume from accessible box volume, which allows for more
          accurate determination of metrics like volumetric energy density for
          determining component efficiency. These estimations help fill in data
          gaps and allow for standardized comparison across different component
          types. Additionally, through our extensive data curation process, we
          identified and filtered out hundreds of components with incorrect or
          inconsistent datasheet information to ensure that the dataset is as
          accurate and reliable as possible.
        </span>
      ),
    },
    {
      question: "Can I plot more than just capacitors and inductors?",
      answer: (
        <span>
          Currently, CompoNet focuses on capacitors and inductors, as these two
          component types are critical for power electronics design and often
          present the most insight in comparative analysis across key metrics
          like cost, weight, and energy density. However, our platform is built
          to accommodate future expansions. We plan to include resistors,
          transformers, and other components as the demand for these comparisons
          grows. Feel free to shoot us a note if you have specific requests for
          component categories you’d like added!
        </span>
      ),
    },
    {
      question: "How often are components on the site updated?",
      answer: (
        <span>
          We aim to refresh the dataset once per year, typically in the
          September-October timeframe. This allows us to capture an annual
          snapshot of the component market and ensures that our data remains
          consistent and relevant for users seeking to track trends or perform
          temporal analyses.
        </span>
      ),
    },
    {
      question: "How can I use CompoNet for my own research or projects?",
      answer: (
        <span>
          CompoNet’s interface allows for easy filtering and plotting of
          different component technologies based on specific metrics. Whether
          you are designing circuits, exploring emerging component trends, or
          looking for cost-effective solutions for new technologies, CompoNet
          offers a rich set of tools for research and design. Additionally, all
          plots generated using CompoNet can be directly downloaded as
          high-quality PNG images, making it simple to integrate visual data
          into research papers, presentations, or engineering reports.
        </span>
      ),
    },
    {
      question: "Is there a way to access the raw data?",
      answer: (
        <span>
          We currently do not provide direct downloads of the raw dataset used
          in CompoNet. However, if you are interested in using the data for
          academic research or have a specific use case, please reach out to us
          directly, and we can discuss potential collaborations!
        </span>
      ),
    },
    {
      question: "Where can I learn more about the research behind CompoNet?",
      answer: (
        <span>
          You can read more about the foundational research in Dr. Brooks’s
          published journal article:{" "}
          <a
            href="/Brooks2024_TPEL_Passive.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="text-founders-rock underline"
          >
            On the Size and Weight of Passive Components: Scaling Trends for
            High-Density Power Converter Designs
          </a>
          . The article outlines the methodologies used for selecting metrics,
          the importance of different component characteristics, and presents
          several empirical models for estimating component performance.
          Additionally, we are working to integrate some of these insights
          directly into the CompoNet interface to provide users with
          context-specific guidance and recommendations.
        </span>
      ),
    },
  ];

  const [openIndex, setOpenIndex] = useState<number | undefined>(undefined);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? undefined : index);
  };

  return (
    <div className="flex w-full h-screen">
      <div className="flex-grow ml-64 items-center justify-center w-full p-8 pt-12 overflow-y-auto">
        <h1 className="text-3xl font-bold mb-8">FAQ</h1>
        <div className="max-w-3xl space-y-6 text-left">
          {faqs.map((faq, index) => (
            <div key={index} className="border-b border-gray-300">
              <button
                onClick={() => toggleFAQ(index)}
                className="w-full text-left py-4 text-lg font-semibold focus:outline-none"
              >
                {faq.question}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className={`float-right text-sm text-gray-700 transition-transform transform ${
                    openIndex === index ? "rotate-180" : ""
                  }`}
                />
              </button>

              {openIndex === index && (
                <div className="transition-transform transform pl-4 pr-2 pb-4 text-gray-700">
                  <p className="text-lg">{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
