import React from "react";
import useWindowSizeObserver from "../hooks/useWindowSizeObserver";

type NotSupportedProps = {
  title: string;
  subtitle?: string;
};

const NotSupported = ({ title, subtitle }: NotSupportedProps) => {
  const { height } = useWindowSizeObserver();

  return (
    <div
      style={{ height: height }}
      className="align-center flex w-full flex-col items-center	justify-center gap-4 bg-black px-12"
    >
      <span className="text-lg font-semibold text-white">{title}</span>
      <span className="text-md text-white">{subtitle}</span>
    </div>
  );
};

export default NotSupported;
