import { useEffect } from "react";
import ReactGA from "react-ga4";
import shalin from "../assets/shalin.jpg";
import nathan from "../assets/nathan.jpg";

export default function AboutPage() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/about",
      title: "About Page",
    });
  }, []);

  return (
    <div className="flex w-full h-screen">
      <div className="flex flex-col items-center w-64 bg-white p-6 pt-32 fixed h-full">
        <div className="flex flex-col items-center mb-8">
          <img
            src={nathan}
            alt="Dr. Nathan Brooks"
            className="w-48 h-48 object-cover shadow-lg" //
          />
          <p className="mt-4 text-lg font-semibold">Dr. Nathan Brooks</p>
        </div>

        <div className="flex flex-col items-center">
          <img
            src={shalin}
            alt="Shalin Shah"
            className="w-48 h-48 object-cover shadow-lg"
          />
          <p className="mt-4 text-lg font-semibold">Shalin Shah</p>
        </div>
      </div>
      <div className="flex-grow ml-64 items-center justify-center w-full p-8 pt-12 overflow-y-auto">
        <h1 className="text-3xl font-bold mb-8">About CompoNet</h1>

        <div className="max-w-3xl space-y-6 text-left">
          <p className="text-lg">
            <span>CompoNet</span> is a novel data visualization and analysis
            tool built by Dr. Nathan Brooks and Shalin Shah to plot and
            visualize passive component technologies on a comprehensive scale.
            Shalin is currently working as a software and perception engineer at
            a robotics startup called{" "}
            <a
              href="https://maticrobots.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-founders-rock underline"
            >
              Matic
            </a>{" "}
            in Mountain View, CA, and Nathan is a professor in the Electrical
            and Computer Engineering department at the{" "}
            <a
              href="https://www.rose-hulman.edu/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-founders-rock underline"
            >
              Rose-Hulman Institute of Technology
            </a>{" "}
            in Terre Haute, IN.
          </p>
          <p className="text-lg">
            We built CompoNet as a way for us and for others to be able to
            visually compare different technologies across many important
            metrics — for example, comparing ceramic and aluminum electrolytic
            capacitors to find broader trends and specific components that have
            the lowest cost and highest energy density, which can greatly inform
            the kind of circuit topology to use when designing something,
            especially in use cases like electric aircraft batteries where
            weight consideration is critical. Our long-term vision extends
            beyond just capturing a static snapshot of the component landscape.
            We aim to continuously track these trends year after year—and
            eventually over decades—to understand how different technologies
            evolve across key metrics.
          </p>
          <p className="text-lg">
            Over the past few years, we have compiled a large-scale dataset of
            1.2 million components sourced from DigiKey and Octopart, and
            developed a suite of tools for processing and visualizing this data.
            We designed CompoNet to make it easy for anyone to explore, plot,
            and analyze the components and metrics that matter most to their
            projects. We hope you find value in CompoNet, and we welcome any
            feedback as we continue to refine and expand the platform!
          </p>
        </div>
      </div>
    </div>
  );
}
